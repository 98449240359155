import { Schema } from '@effect/schema'

export const StructuredAddress = Schema.Struct({
  addressLine: Schema.optional(Schema.String),
  city: Schema.optional(Schema.String),
  country: Schema.optional(Schema.String),
  housenumber: Schema.optional(Schema.String),
  state: Schema.optional(Schema.String),
  postcode: Schema.optional(Schema.String),
  street: Schema.optional(Schema.String),
  text: Schema.String,
})
