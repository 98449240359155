import { ActionIcon, Anchor, Avatar, Box, Button, Checkbox, Flex, Stepper, Text, TextInput, Title } from '@mantine/core'
import { isEmail, isNotEmpty, useForm } from '@mantine/form'
import { notifications } from '@mantine/notifications'
import { IconArrowLeft, IconAt, IconBriefcaseFilled, IconId } from '@tabler/icons-react'
import { useUnit } from 'effector-react'
import { useCallback, useState } from 'react'
import ConfettiExplosion from 'react-confetti-explosion'
import { useTranslation } from 'react-i18next'

import { saveInfo } from './state'
import { conditionalCheck } from './uitls'

import css from './registration-form.module.scss'

interface ContactPerson {
  name: string
  email: string
  phoneNumber: string
  title: string
}

const techPersonNeeded = (_: unknown, values: { contactPersonIsTechPerson: boolean }) =>
  values.contactPersonIsTechPerson === false

const FORM_INITIAL_VALUES = {
  domainName: ``,
  organization: {
    name: ``,
    address: ``,
    chamberOfCommerceNumber: ``,
  },
  contactPerson: {
    name: ``,
    email: ``,
    phoneNumber: ``,
    title: ``,
  } satisfies ContactPerson,
  contactPersonIsTechPerson: true,
  techContactPerson: {
    name: ``,
    email: ``,
    phoneNumber: ``,
    title: ``,
  } as ContactPerson | undefined,
}

export const RegistrationForm = () => {
  const [step, setStep] = useState(0)
  const [data, setData] = useState(FORM_INITIAL_VALUES)
  const { t } = useTranslation()

  const saving = useUnit(saveInfo.pending)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const collectData = useCallback((values: Record<string, any>) => {
    const newData = {
      ...data,
      ...values,
    }
    setData(newData)
    if (step < 2) {
      return setStep(step + 1)
    }

    if (newData.contactPersonIsTechPerson) {
      delete newData.techContactPerson
      setData(newData)
    }
    saveInfo(newData)
      .then(() => setStep(3))
      .catch(() => {
        notifications.show({
          color: `red`,
          title: t(`onboarding.error`),
          message: t(`onboarding.errors.saveFailed`),
        })

        setStep(2)
      })
  }, [step, data, t])

  const step1Form = useForm({
    initialValues: {
      domainName: FORM_INITIAL_VALUES.domainName,
    },
    validate: {
      domainName: isNotEmpty(t(`onboarding.errors.domainName`)),
    },
  })

  const step2Form = useForm({
    initialValues: {
      organization: FORM_INITIAL_VALUES.organization,
    },
    validate: {
      organization: {
        name: isNotEmpty(t(`onboarding.errors.organizationName`)),
        address: isNotEmpty(t(`onboarding.errors.organizationAddress`)),
        chamberOfCommerceNumber: isNotEmpty(t(`onboarding.errors.organizationId`)),
      },
    },
  })

  const step3Form = useForm({
    initialValues: {
      contactPerson: FORM_INITIAL_VALUES.contactPerson,
      techContactPerson: FORM_INITIAL_VALUES.techContactPerson,
      contactPersonIsTechPerson: FORM_INITIAL_VALUES.contactPersonIsTechPerson,
    },
    validate: {
      contactPerson: {
        name: isNotEmpty(t(`onboarding.errors.contactName`)),
        email: isEmail(t(`onboarding.errors.contactEmail`)),
        phoneNumber: isNotEmpty(t(`onboarding.errors.contactPhoneNumber`)),
        title: isNotEmpty(t(`onboarding.errors.contactTitle`)),
      },
      techContactPerson: {
        name: conditionalCheck(
          techPersonNeeded,
          isNotEmpty(t(`onboarding.errors.contactName`)),
        ),
        email: conditionalCheck(
          techPersonNeeded,
          isEmail(t(`onboarding.errors.contactEmail`)),
        ),
        phoneNumber: conditionalCheck(
          techPersonNeeded,
          isNotEmpty(t(`onboarding.errors.contactPhoneNumber`)),
        ),
        title: conditionalCheck(
          techPersonNeeded,
          isNotEmpty(t(`onboarding.errors.contactTitle`)),
        ),
      },
    },
  })

  const proceed = (valid: boolean, loading?: boolean) => (
    <Flex justify="space-between" mt={24} align="center">
      {step > 0
        ? (
          <ActionIcon variant="light" radius="lg" color="dark" onClick={() => setStep(step - 1)}>
            <IconArrowLeft size={24} />
          </ActionIcon>
          )
        : <div />}
      <Button disabled={!valid} loading={loading || saving} type="submit">{t(`onboarding.form.proceed`)}</Button>
    </Flex>
  )

  const domainInputPrefix = (
    <span className={css.domainNamePrefix}>
      me
      <IconAt size={16} />
    </span>
  )

  const defaultProps = {
    'autoComplete': `off`,
    'mb': 12,
    'withAsterisk': true,
    'data-1p-ignore': true,
  }

  return (
    <>
      <Title order={2} size="h2" mb={48}>{t(`onboarding.title`)}</Title>
      <Stepper active={step} orientation="vertical" className={css.stepper}>
        <Stepper.Step value={0} label={t(`onboarding.step1.label`)} description={t(`onboarding.step1.description`)}>
          <form onSubmit={step1Form.onSubmit(collectData)} id="step1" key="step1" className={css.formWrap}>
            <Flex justify="center" align="center" mb={24} pr={12}>
              <Avatar size="md" color="dark" mr={12}><IconAt size={24} /></Avatar>
              <Title order={5}>{t(`onboarding.step1.label`)}</Title>
            </Flex>
            <Box className={css.formInner}>
              <TextInput
                {...step1Form.getInputProps(`domainName`)}
                name="domainName"
                autoFocus
                className={css.domainNameInput}
                label={t(`onboarding.form.domainName`)}
                leftSection={domainInputPrefix}
                {...defaultProps}
              />
            </Box>
            {proceed(step1Form.isValid())}
          </form>
        </Stepper.Step>
        <Stepper.Step value={2} label={t(`onboarding.step2.label`)} description={t(`onboarding.step2.description`)}>
          <form onSubmit={step2Form.onSubmit(collectData)} id="step2" key="step2" className={css.formWrap}>
            <Flex justify="center" align="center" mb={24} pr={12}>
              <Avatar size="md" color="indigo" mr={12}><IconBriefcaseFilled size={24} /></Avatar>
              <Title order={5}>{t(`onboarding.step2.label`)}</Title>
            </Flex>
            <Box className={css.formInner}>
              <TextInput
                {...step2Form.getInputProps(`organization.name`)}
                name="organizationName"
                autoFocus
                label={t(`onboarding.form.organizationName`)}
                {...defaultProps}
              />
              <TextInput
                {...step2Form.getInputProps(`organization.address`)}
                name="organizationAddress"
                label={t(`onboarding.form.organizationAddress`)}
                {...defaultProps}
              />
              <TextInput
                {...step2Form.getInputProps(`organization.chamberOfCommerceNumber`)}
                name="organizationId"
                label={t(`onboarding.form.organizationId`)}
                {...defaultProps}
              />
            </Box>
            {proceed(step2Form.isValid())}
          </form>
        </Stepper.Step>
        <Stepper.Step value={3} label={t(`onboarding.step3.label`)} description={t(`onboarding.step3.description`)}>
          <form onSubmit={step3Form.onSubmit(collectData)} id="step3" key="step3" className={css.formWrap}>
            <Box className={css.formInner}>
              <Flex justify="center" align="center" mb={24} pr={12}>
                <Avatar size="md" color="indigo" mr={12}><IconId size={24} /></Avatar>
                <Title order={5}>{t(`onboarding.mainContact`)}</Title>
              </Flex>

              <TextInput {...step3Form.getInputProps(`contactPerson.name`)} label={t(`onboarding.form.contactName`)} name="contactName" autoFocus {...defaultProps} />
              <TextInput {...step3Form.getInputProps(`contactPerson.email`)} label={t(`onboarding.form.contactEmail`)} name="contactEmail" {...defaultProps} />
              <TextInput {...step3Form.getInputProps(`contactPerson.phoneNumber`)} label={t(`onboarding.form.contactPhoneNumber`)} name="contactPhoneNumber" {...defaultProps} />
              <TextInput {...step3Form.getInputProps(`contactPerson.title`)} label={t(`onboarding.form.contactTitle`)} name="contactTitle" {...defaultProps} />
              <Checkbox
                {...step3Form.getInputProps(`contactPersonIsTechPerson`)}
                checked={step3Form.getInputProps(`contactPersonIsTechPerson`).value}
                label={t(`onboarding.form.sameAsTechContact`)}
                name="sameAsTechPerson"
              />

              {
              step3Form.values.contactPersonIsTechPerson === false && (
                <>
                  <Flex justify="center" align="center" mb={24} mt={48} pr={12}>
                    <Avatar size="md" color="lime" mr={12}><IconId size={24} /></Avatar>
                    <Title order={5}>{t(`onboarding.techContact`)}</Title>
                  </Flex>
                  <TextInput {...step3Form.getInputProps(`techContactPerson.name`)} label={t(`onboarding.form.contactName`)} name="techContactName" {...defaultProps} />
                  <TextInput {...step3Form.getInputProps(`techContactPerson.email`)} label={t(`onboarding.form.contactEmail`)} name="tectContactEmail" {...defaultProps} />
                  <TextInput {...step3Form.getInputProps(`techContactPerson.phoneNumber`)} label={t(`onboarding.form.contactPhoneNumber`)} name="techContactPhoneNumber" {...defaultProps} />
                  <TextInput {...step3Form.getInputProps(`techContactPerson.title`)} label={t(`onboarding.form.contactTitle`)} name="tectContactTitle" {...defaultProps} />
                </>
              )
            }
            </Box>
            {proceed(step3Form.isValid())}
          </form>
        </Stepper.Step>
        <Stepper.Completed>
          <ConfettiExplosion zIndex={9999} />
          <Text style={{ textAlign: `center`, fontSize: `3rem` }} fw={500} variant="gradient">
            {t(`onboarding.success`)}
          </Text>
          <Text style={{ textAlign: `center`, fontSize: `3rem` }}>
            🎉
          </Text>
          <Text style={{ textAlign: `center` }} dangerouslySetInnerHTML={{ __html: t(`onboarding.successText`) }} />
          <Flex justify="center" mt={24}>
            <Anchor href="#" onClick={() => window.location.reload()}>
              {t(`onboarding.checkStatus`)}
            </Anchor>
          </Flex>
        </Stepper.Completed>
      </Stepper>
    </>
  )
}
