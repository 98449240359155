import i18next from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend, { HttpBackendOptions } from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

const instance = i18next
const init = instance
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(Backend)
  .init<HttpBackendOptions>({
    defaultNS: `translation`,
    ns: [`translation`],
    fallbackLng: `en`,
    debug: process.env.NODE_ENV !== `production`,
    load: `languageOnly`,
    react: {
      useSuspense: false,
    },
    backend: {
      request: (_options, url, _payload, callback) => {
        import(`./locales/${url}.yaml`)
          .then((response) => {
            callback(null, {
              status: 200,
              data: response.default,
            })
          })
          .catch((error) => {
            console.error(error)
            callback(error, { status: 500, data: {} })
          })
      },
      loadPath: `{{lng}}`,
    },
  })

export { instance, init }
