import * as Effect from 'effect/Effect'
import { pipe } from 'effect/Function'

import { parseCustomerInformation } from '../schema'
import { ApiClient } from '../services/api-client'

import { runPromise } from './runtime'

export const loadOnboardingState = (marketplaceToken: string) => pipe(
  ApiClient,
  Effect.flatMap(client => client.loadOnboardingState(marketplaceToken)),
  runPromise,
)

export const saveInfo = (args: { onboardingId: { customerIdentifier: string; productCode: string }; info: unknown }) => pipe(
  ApiClient,
  Effect.flatMap(client => pipe(
    args.info,
    parseCustomerInformation,
    Effect.flatMap(info => client.saveOnboardingInfo(args.onboardingId, info)),
    Effect.tapError(Effect.logError),
  )),
  runPromise,
)

export const checkUsername = (args: { onboardingId: { customerIdentifier: string; productCode: string }; username: string }) =>
  ApiClient.pipe(
    Effect.flatMap(client => client.checkUsername(args.onboardingId, args.username)),
    Effect.tapError(Effect.logError),
    runPromise,
  )
