import { createTheme, MantineProvider } from '@mantine/core'
import { Notifications } from '@mantine/notifications'
import ReactDOM from 'react-dom/client'
import { I18nextProvider } from 'react-i18next'

import { instance } from './i18n/index.ts'
import App from './App.tsx'

import '@mantine/core/styles.css'
import '@mantine/notifications/styles.css'
import './index.css'

const theme = createTheme({
  /** Put your mantine theme override here */
})

ReactDOM.createRoot(document.getElementById(`root`)!).render(
  <I18nextProvider i18n={instance}>
    {/* <React.StrictMode> */}
    <MantineProvider theme={theme}>
      <Notifications position="top-center" />
      <App />
    </MantineProvider>
    {/* </React.StrictMode> */}
  </I18nextProvider>,
)
