import { GenericTag } from 'effect/Context'
import * as Effect from 'effect/Effect'
import { pipe } from 'effect/Function'
import * as Layer from 'effect/Layer'
import AxiosLib, { AxiosInstance } from 'axios'

import { AppConfig } from '../state/config'

export const Axios = GenericTag<AxiosInstance>(`axios`)

export const AxiosLive = Layer.effect(
  Axios,
  pipe(
    AppConfig,
    Effect.map(({ apiUrl }) => AxiosLib.create({ baseURL: apiUrl })),
  ),
)
