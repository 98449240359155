import { Alert } from '@mantine/core'
import { useTranslation } from 'react-i18next'

export const Error = () => {
  const { t } = useTranslation()

  return (
    <Alert title="Error" color="pink" variant="filled">
      {t(`onboarding.errors.onboardingFailed`)}
    </Alert>
  )
}
