import * as ConfigProvider from 'effect/ConfigProvider'
import * as Effect from 'effect/Effect'
import { pipe } from 'effect/Function'
import * as Layer from 'effect/Layer'
import * as Runtime from 'effect/Runtime'

import { ApiClientLive } from '../services/api-client'
import { AxiosLive } from '../services/axios'

const importMetaProvider = ConfigProvider.fromMap(new Map(Object.entries(import.meta.env)))

const myRuntime = pipe(
  Layer.provideMerge(
    ApiClientLive,
    AxiosLive,
  ),
  Layer.provide(Layer.setConfigProvider(importMetaProvider)),
  Layer.toRuntime,
  Effect.scoped,
  Effect.runSync,
)

export const runPromise = Runtime.runPromise(myRuntime)
