import { Anchor, Text, Title } from '@mantine/core'
import { Trans, useTranslation } from 'react-i18next'

export const Finished = () => {
  const { t } = useTranslation()
  return (
    <div>
      <Title order={2} size="h2" mb={24}>{t(`onboarding.finished`)}</Title>
      <Trans t={t} i18nKey="finished">
        <Text fw={500} mb={12}>
          You should've received an email with instructions on how to
          <br />
          setup you email server and Outlook 365, as well as access
          <br />
          SecuMailer self-service portal.
          <br />
        </Text>
        <Text size="sm">
          Please reach out to
          {` `}
          <Anchor href="mailto:support@secumailer.com">support@secumailer.com</Anchor>
          {` `}
          in case you haven't.
        </Text>
      </Trans>
    </div>
  )
}
