import * as Match from 'effect/Match'
import { Paper } from '@mantine/core'
import { OnboardingEntity } from '@marketplace/functions/src/schema/onboarding'
import { OnboardingStatus } from '@marketplace/functions/src/services/database/onboarding/const'
import { RouteObject } from 'react-router'
import { createRoute } from 'react-router-url-params'

import { Error } from './Error'
import { Finished } from './Finished'
import { RegistrationForm } from './RegistrationForm'
import { Status } from './Status'

export const loginRoute = createRoute(`/login`)
export const resetPasswordRoute = createRoute(`/reset-password`)
export const changePasswordRoute = createRoute(`/change-password`)
export const homeRoute = createRoute(`/`)

export const getRoutes = (onboarding: OnboardingEntity): RouteObject[] => {
  const currentComponent = Match.value(onboarding)
    .pipe(
      Match.when({ status: OnboardingStatus.Registered }, () => <RegistrationForm />),
      Match.when({ status: OnboardingStatus.Configured }, () => <Status />),
      Match.when({ status: OnboardingStatus.Finished }, () => <Finished />),
      Match.when({ status: OnboardingStatus.Error }, () => <Error />),
      Match.exhaustive,
    )

  return [
    {
      path: ``,
      element: <Paper shadow="sm" p="md">
        {currentComponent}
      </Paper>,
    },
  ]
}
