import { Schema } from '@effect/schema'

import { ContactPerson } from './contact-person'
import { StructuredAddress } from './structured-address'

export const OrganizationInfo = Schema.Struct({
  name: Schema.String,
  address: StructuredAddress,
  chamberOfCommerceNumber: Schema.String,
})

export const OnboardingCustomerInformation = Schema.Struct({
  organization: OrganizationInfo,
  contactPerson: ContactPerson,
  techContactPerson: Schema.optional(ContactPerson),
  domainName: Schema.String,
})

export const OnboardingCustomerInformationInput = OnboardingCustomerInformation
  .pipe(
    Schema.omit(`organization`),
    Schema.extend(
      Schema.Struct({
        organization: OrganizationInfo
          .pipe(
            Schema.omit(`address`),
            Schema.extend(Schema.Struct({
              address: Schema.String,
            })),
          ),
      }),
    ),
  )

export type OnboardingCustomerInformation = Schema.Schema.Type<typeof OnboardingCustomerInformation>
export type OnboardingCustomerInformationInput = Schema.Schema.Type<typeof OnboardingCustomerInformationInput>
