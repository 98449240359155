import * as Context from 'effect/Context'
import { TaggedError } from 'effect/Data'
import * as Effect from 'effect/Effect'
import * as Layer from 'effect/Layer'
import * as Schema from '@effect/schema'
import { OnboardingEntity } from '@marketplace/functions/src/schema/onboarding'
import { OnboardingCustomerInformationInput } from '@marketplace/functions/src/schema/onboarding/customer-information'
import { pipe } from 'effect'

import { encodeCustomerInformation } from '../schema'

import { Axios } from './axios'

export class ApiError extends TaggedError(`ApiError`)<{ message: string }> {}

export const parseOnboardingEntity = Schema.Schema.decode(OnboardingEntity)
export const encodeOnboardingEntity = Schema.Schema.encode(OnboardingEntity)

export const loadOnboardingState = Effect.gen(function* (_) {
  const axios = yield* _(Axios)

  return (marketplaceToken: string) => pipe(
    Effect.tryPromise(() =>
      axios.post(`/marketplace-token`, {
        marketplaceToken,
      }),
    ),
    Effect.mapError(error => new ApiError({ message: `${error}` })),
    Effect.flatMap(response => parseOnboardingEntity(response.data)),
  )
})

export const saveOnboardingInfo = Effect.gen(function* (_) {
  const axios = yield* _(Axios)

  return (onboardingId: { customerIdentifier: string; productCode: string }, info: Schema.Schema.Schema.Type<typeof OnboardingCustomerInformationInput>) => pipe(
    encodeCustomerInformation(info),
    Effect.flatMap(info =>
      Effect.tryPromise(() =>
        axios.post(`/save-info`, {
          onboardingId,
          info,
        }),
      ),
    ),
    Effect.mapError(error => new ApiError({ message: `${error}` })),
    Effect.flatMap(response => parseOnboardingEntity(response.data)),
  )
})

export const checkUsername = Effect.gen(function* (_) {
  const axios = yield* _(Axios)

  return (onboardingId: { customerIdentifier: string; productCode: string }, username: string) => pipe(
    Effect.tryPromise(() =>
      axios.post(`/check-username`, {
        onboardingId,
        username,
      }),
    ),
    Effect.mapError(error => new ApiError({ message: `${error}` })),
  )
})

export interface ApiClient {
  loadOnboardingState: Effect.Effect.Success<typeof loadOnboardingState>
  saveOnboardingInfo: Effect.Effect.Success<typeof saveOnboardingInfo>
  checkUsername: Effect.Effect.Success<typeof checkUsername>
}

export const ApiClient = Context.GenericTag<ApiClient>(`ApiClient`)

export const ApiClientLive = Layer.effect(
  ApiClient,
  Effect.all({
    loadOnboardingState,
    saveOnboardingInfo,
    checkUsername,
  }),
)
