import * as Effect from 'effect/Effect'
import { flow } from 'effect/Function'
import { Schema } from '@effect/schema'

import { ONBOARDING_ENTITY } from '../services/database/onboarding/const'

import { OnboardingCustomerInformation } from './onboarding/customer-information'

export enum OnboardingStatus {
  Registered = `REGISTERED`,
  Configured = `CONFIGURED`,
  Finished = `FINISHED`,
  Error = `ERROR`,
}

export const OnboardingRegistered = Schema.Struct({
  status: Schema.Literal(OnboardingStatus.Registered),
  customerIdentifier: Schema.String,
  customerAccountId: Schema.String,
  productCode: Schema.String,
  created: Schema.DateFromString,
  updated: Schema.DateFromString,
})

export const OnboardingConfigured = OnboardingRegistered.pipe(
  Schema.omit(`status`),
  Schema.extend(Schema.Struct({
    status: Schema.Literal(OnboardingStatus.Configured, OnboardingStatus.Finished),
    onboardingInfo: OnboardingCustomerInformation,
  })),
)
export const OnboardingError = OnboardingRegistered.pipe(
  Schema.omit(`status`),
  Schema.extend(Schema.Struct({
    status: Schema.Literal(OnboardingStatus.Error),
    onboardingInfo: Schema.optional(OnboardingCustomerInformation),
  })),
)

export const OnboardingEntity = Schema.Union(
  OnboardingRegistered,
  OnboardingConfigured,
  OnboardingError,
)

export const OnboardingDatabaseRecord = Schema.Struct({
  pk: Schema.String,
  sk: Schema.String,
  status: Schema.String,
  customerAccountId: Schema.String,
  productCode: Schema.String,
  created: Schema.String,
  updated: Schema.String,
  onboardingInfo: Schema.optional(OnboardingCustomerInformation),
}).pipe(
  Schema.transform(OnboardingEntity, {
    decode: ({ pk, sk, ...rest }) => {
      return {
        customerIdentifier: pk,
        ...rest,
      } as never
    },

    encode: ({ customerIdentifier, ...rest }) => {
      return {
        ...rest,
        pk: customerIdentifier,
        sk: `${ONBOARDING_ENTITY}#${rest.productCode}`,
      }
    }
  }),
)

export const parseOnboardingRecord = Schema.decodeUnknown(OnboardingDatabaseRecord)
export const toOnboardingRecord = flow(Schema.encode(OnboardingDatabaseRecord), Effect.runSync)
export type OnboardingDatabaseRecord = Schema.Schema.Encoded<typeof OnboardingDatabaseRecord>
export type OnboardingEntity = Schema.Schema.Type<typeof OnboardingEntity>
