import { OnboardingEntity } from '@marketplace/functions/src/schema/onboarding'
import { attach, createEffect, createEvent, createStore, sample } from 'effector'

import * as effects from './effects'

export type OnboardingUiState =
  {
    state: 'loading'
  } |
  {
    state: 'loaded'
    onboarding: OnboardingEntity
  } |
  {
    state: 'error'
    error: string
  }

const $onboarding = createStore<OnboardingUiState>({
  state: `loading`,
})

const $successState = createStore<OnboardingEntity | null>(null)

const confirm = createEvent(`confirm`)

const loadOnboardingState = createEffect(`loadOnboardingState`, {
  handler: effects.loadOnboardingState,
})

const saveInfoEffect = createEffect(`saveInfo`, {
  handler: effects.saveInfo,
})

const checkUsernameEffect = createEffect(`checkUsername`, {
  handler: effects.checkUsername,
})

const checkUsername = attach(
  {
    effect: checkUsernameEffect,
    source: $onboarding,
    name: `checkUsernameAttached`,
    mapParams(username: string, state) {
      if (state.state === `loaded`) {
        return {
          onboardingId: {
            customerIdentifier: state.onboarding.customerIdentifier,
            productCode: state.onboarding.productCode,
          },
          username,
        }
      }
      return {
        onboardingId: {
          customerIdentifier: ``,
          productCode: ``,
        },
        username: ``,
      }
    },
  },
)

const saveInfo = attach(
  {
    effect: saveInfoEffect,
    source: $onboarding,
    name: `saveInfoAttached`,
    mapParams: (info: unknown, state) => {
      if (state.state === `loaded`) {
        return {
          onboardingId: {
            customerIdentifier: state.onboarding.customerIdentifier,
            productCode: state.onboarding.productCode,
          },
          info,
        }
      }
      return {
        onboardingId: {
          customerIdentifier: ``,
          productCode: ``,
        },
        info: {},
      }
    },
  },
)

$onboarding
  .on(loadOnboardingState.doneData, (_, onboarding) => ({
    state: `loaded`,
    onboarding,
  }))
  .on(loadOnboardingState.failData, (_, error) => ({
    state: `error`,
    error: `${error}`,
  }))

$successState.on(saveInfo.doneData, (_, onboarding) => onboarding)

$onboarding.on(
  sample(
    {
      source: $successState,
      clock: confirm,
    },
  ),
  (state, onboarding) => {
    if (onboarding) {
      return { ...state, onboarding }
    }

    return state
  },
)

export {
  $onboarding,
  loadOnboardingState,
  saveInfo,
  confirm,
  checkUsername,
}
