import { Alert, Loader } from '@mantine/core'
import { useUnit } from 'effector-react'
import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useRoutes } from 'react-router'
import { BrowserRouter } from 'react-router-dom'
import { StringParam, useQueryParam } from 'react-router-url-params'

import { getRoutes } from './routes'
import { $onboarding, loadOnboardingState } from './state'

export const Routes = () => {
  const [marketplaceToken] = useQueryParam(`x-amzn-marketplace-token`, StringParam)
  const state = useUnit($onboarding)
  const { t } = useTranslation()

  useEffect(
    () => {
      loadOnboardingState(marketplaceToken ?? ``)
    }
    , [marketplaceToken])

  const currentRoutes = useMemo(() => {
    if (state.state === `loaded`) {
      return getRoutes(state.onboarding)
    }

    if (state.state === `error`) {
      return [
        {
          path: ``,
          element: <Alert title="Error" color="pink" variant="filled">
            {t(`onboarding.errors.tokenVerificationFailed`)}
          </Alert>,
        },
      ]
    }

    return [
      {
        path: ``,
        element: <Loader size={30} />,
      },
    ]
  }, [state, t])

  return useRoutes(currentRoutes)
}

function App() {
  return (
    <BrowserRouter>
      <Routes />
    </BrowserRouter>
  )
}

export default App
